<template>
  <template
    v-if="
      hasOneShowingChild(menu.children, menu) &&
      (!data.onlyOneChild.children || data.onlyOneChild.noShowingChildren) &&
      !menu.alwaysShow
    "
  >
    <a-menu-item :key="resolvePath(data.onlyOneChild.path)">
      <template #icon>
        <div class="flex-center menu-icon">
          <img class="menu-icon-img" :src="menu.meta?.icon" />
          <img class="menu-icon-img-checked" :src="menu.meta?.icon_checked" />
        </div>
      </template>
      <router-link :to="resolvePath(data.onlyOneChild.path)">
        {{ menu?.meta?.title }}
      </router-link>
    </a-menu-item>
  </template>
  <template v-else>
    <a-sub-menu :key="menu.path">
      <template #icon>
        <div class="flex-center menu-icon">
          <img class="menu-icon-img" :src="menu.meta?.icon" />
          <img class="menu-icon-img-checked" :src="menu.meta?.icon_checked" />
        </div>
      </template>
      <template #title>{{ menu?.meta?.title }}</template>
      <sidebar-item
        v-for="child in menu.children"
        :key="resolvePath(child.path)"
        :menu="child"
        :base-path="resolvePath(child.path)"
      />
    </a-sub-menu>
  </template>
</template>

<script setup>
import { resolve } from 'pathe' // path包es代码实现
import { computed, onMounted, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useUserStore } from '@/store/modules/user'
import { asyncRoutes } from '@/router/routes'
const route = useRoute()
const keepAlive = !!route.meta.keepAlive

const router = useRouter()
const user = useUserStore()

const props = defineProps({
  basePath: {
    type: String,
    default: '',
  },
  menu: {
    type: Object,
    required: true,
  },
})

const data = reactive({
  onlyOneChild: {},
})
const hasOneShowingChild = (children = [], parent) => {
  const showingChildren = children.filter((item) => {
    if (item.hidden) {
      return false
    } else {
      // Temp set(will be used if only has one showing child)
      data.onlyOneChild = item
      return true
    }
  })

  // When there is only one child router, the child router is displayed by default
  if (showingChildren.length === 1) {
    return true
  }

  // Show parent if there are no child router to display
  if (showingChildren.length === 0) {
    data.onlyOneChild = { ...parent, path: '', noShowingChildren: true }
    return true
  }

  return false
}
const resolvePath = (routePath) => {
  return resolve(props.basePath, routePath)
}
const resolvePath2 = (routePath) => {
  console.info(resolve(props.basePath, routePath))
  return resolve(props.basePath, routePath)
}
</script>

<style lang="less" scoped>
.menu-icon-img-checked {
  display: none;
}
</style>
