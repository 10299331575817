import { defineStore } from 'pinia'

export const useAccountStore = defineStore('account', {
  state() {
    return {
      currentRole: {},
      filterList: [],
    }
  },
  getters: {
    /**
     * 获取已筛选的数据
     * @return {*[]}
     */
    getFilterListChecked(state) {
      let arr = []
      console.info(state.filterList)
      state.filterList.menuConfigs?.map(_ => {
        console.info(_)
        _.operates.map(item => {
          console.info('item')
          if (item.checked) {
            arr.push(item.menuId)
          }
        })
      })
      console.info('arr', arr)
      return arr
    }
  },
  actions: {
    setFilterList(data) {
      this.filterList = data
    },
    
    setCurrentRole(data) {
      this.role = data
    },
  },
  persist: {
    enabled: true
  }
})
