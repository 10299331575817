<template>
  <a-menu v-model:openKeys="openKeys" :selected-keys="selectedKeys" class="subMenu" mode="inline">
    <sidebar-item v-for="item in accessRoutes" :key="item.path" :menu="item" :base-path="item.path" />
  </a-menu>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { asyncRoutes } from '@/router/routes'
import { useUserStore } from '@/store/modules/user'
import SidebarItem from './SidebarItem.vue'

const route = useRoute()
const selectedKeys = ref([]) // 菜单默认选中项
const openKeys = ref([]) // 子菜单默认展开项
const user = useUserStore()
const permission = user.getUserPermission
const accessRoutes = ref([])

watch(
  () => route.path,
  () => {
    initMenuStyle()
  }
)

const initMenuStyle = (isResetOpen = false) => {
  const routeArr = route.path.split('/').filter((r) => r)
  let routesLayer = { children: asyncRoutes }
  let openKeysT = []
  selectedKeys.value = []
  for (let i = 0; routeArr.length; i++) {
    const routesLayerTemp = routesLayer.children.find((c) => c.path.includes(routeArr[i]))
    if (routesLayerTemp && !routesLayerTemp.hidden) {
      openKeysT.push(routesLayerTemp.path)
      routesLayer = routesLayerTemp
      if (!routesLayer.children) {
        isResetOpen && (openKeys.value = openKeysT)
        selectedKeys.value = [openKeysT.join('/')]
        return
      }
    } else if (routesLayerTemp && routesLayerTemp.hidden) {
      const item = routesLayer.children.find((r) => !r.hidden)
      openKeysT.push(item.path)
      isResetOpen && (openKeys.value = openKeysT)

      if (routesLayerTemp.path == 'AfterSalesDetails') {
        selectedKeys.value = ['/order/mycreate/afterSales']
        return
      }
      if (routesLayerTemp.path == 'myApprovalAfterSalesDetails') {
        selectedKeys.value = ['/order/myapproval/afterSales']
        return
      }
      selectedKeys.value = [openKeysT.join('/')]
      return
    }
  }
}

onMounted(() => {
  accessRoutes.value = filterAsyncRoutes(asyncRoutes)
  initMenuStyle(true)
})

//权限判断
const filterAsyncRoutes = (routes) => {
  const res = []
  routes.forEach((route) => {
    const tmp = { ...route }
    if (hasPermission(tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children)
      }
      res.push(tmp)
    }
  })

  return res
}

const hasPermission = (route) => {
  const roleArr = (permission || []).map((_) => _.routeCode)

  if (route.meta && route.meta.roleCode) {
    return route.meta.roleCode === 'default' || roleArr.includes(route.meta.roleCode)
  }
}
</script>

<style lang="less"></style>
