import axios from 'axios'
import { Modal, notification, message } from 'ant-design-vue/es/components'
import { useUserStore } from '@/store/modules/user'
import { useRouter } from 'vue-router'

/**
 * @description 自行处理错误信息及提示
 * 需要在请求参数中添加：responseHandleErrUserDefined = true 参数
 * @param response
 */
const handleErrUserDefined = (response) => {
  try {
    if (!response.config) {
      return false
    }

    // 需逐步完善各种情况
    if (response.config.method.toLowerCase() === 'post') {
      const data = response.config.data
      const newDate = (data && JSON.parse(data)) || {}
      if (newDate.responseHandleErrUserDefined) {
        return true
      }
    } else if (response.config.method.toLowerCase() === 'get') {
      const data = response.config.params
      if (data && data.responseHandleErrUserDefined) {
        return true
      }
    }

    return false
  } catch (e) {
    return false
  }
}

const err = (error) => {
  if (error?.data) {
    let data = error.data
    const token = localStorage.getItem('token')
    switch (data.status) {
      case 403:
        message.error('拒绝访问').then()
        // notification.error({
        //   message: '系统提示',
        //   description: '拒绝访问',
        //   duration: 4,
        // })
        break
      case 500:
        if (token && data.message.includes('Token失效')) {
          Modal.error({
            title: '登录已过期',
            content: '很抱歉，登录已过期，请重新登录',
            okText: '重新登录',
            mask: false,
            onOk: () => {
              // 清除所有缓存
              localStorage.clear()
              window.location.reload()
            },
          })
        } else {
          message.error('哎呀（V^V）,网络走丢了').then()
          // notification.error({
          //   message: '系统提示',
          //   description: '哎呀（V^V）,网络走丢了',
          // })
        }
        break
      case 404:
        message.error('很抱歉，资源未找到!').then()
        // notification.error({
        //   message: '系统提示',
        //   description: '很抱歉，资源未找到!',
        //   duration: 4,
        // })
        break
      default:
        message.error(data.message).then()
        // notification.error({
        //   message: '系统提示',
        //   description: data.message,
        //   duration: 4,
        // })
        break
    }
  } else {
    if (error.response && error.response.status === 504 && error.response.statusText === 'Gateway Time-out') {
      message.error('接口请求超时').then()
    }
  }
  return Promise.reject(error)
}

// 创建实例
const request = axios.create({
  baseURL: import.meta.env.VITE_APP_BASE_API_URL,
  timeout: 600000, //10min
  headers: { 'Content-Type': 'application/json;' },
})

const redirectToLogin = () => {
  if (window.location.href.indexOf('/auth/login') === -1) {
    message.error('登录已过期').then()
    // 清除所有缓存
    localStorage.clear()
    window.location.href = `${import.meta.env.BASE_URL}auth/login`
  }
}

const redirectToUpdatePwd = () => {
  const hookRouter = useRouter()
  message.error('初次登录，请修改原始密码').then()
  // 清除所有缓存
  localStorage.clear()
  hookRouter.replace({ path: '/auth/update-password', state: { notNeedBack: true } }).then()
}
// 请求拦截
request.interceptors.request.use(async (request) => {
  const user = useUserStore()
  request.headers['token'] = user.token || ''
  return request
})

// 拦截响应
request.interceptors.response.use(async (response) => {
  // 如果是文件，自动下载
  if (response.data instanceof Blob) {
    const disposition = response.headers['content-disposition']
    if (disposition) {
      const filename = disposition.split('filename=')[1]
      const blob = new Blob([response.data])
      const downloadElement = document.createElement('a')
      const href = window.URL.createObjectURL(blob)
      downloadElement.href = href
      downloadElement.download = decodeURIComponent(filename)
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
      window.URL.revokeObjectURL(href)
      return Promise.resolve(response)
    }

    // 如果没有content-disposition可能接口报错，需要解析下
    const data = await response.data.text()
    try {
      const obj = JSON.parse(data)
      if (obj && obj.code !== '200') {
        message.error(obj.message).then()
        // notification.error({
        //   message: '系统提示',
        //   description: obj.message,
        //   duration: 4,
        // })
        return Promise.reject(response.data)
      } else {
        return Promise.resolve(response.data)
      }
    } catch {
      return Promise.reject(response.data)
    }
  }
  switch (response.data.code) {
    case '200':
      return Promise.resolve(response.data)
    case '700': // 用户登录过期
    case '10004': //账号锁定
      redirectToLogin()
      return Promise.reject(response.data)
    case '10003': // 初次登录
      redirectToUpdatePwd()
      return Promise.reject(response.data)
    case '704':
    case '1002':
      // 不显示错误信息，自行处理错误内容
      return Promise.reject(response.data)
    default:
      if (!handleErrUserDefined(response)) {
        message.error(response.data.message).then()
      }
      return Promise.reject(response.data)
  }
}, err)

export default request
