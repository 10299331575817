import { useUserStore } from "@/store/modules/user"

/**
 * 按钮权限控制
 */
export default {
  mounted(el, { value }) {
    let authority = false

    const user = useUserStore()
    // const permission = user.getUserPermission
    //
    // if (permission && Array.isArray(permission)) {
    //   const index = permission.findIndex(item => item.menuCode === value)
    //   authority = index > -1
    // }

    authority = user.checkInPermission(value)

    if (!authority) {
      el.remove()
    }
  }
}
