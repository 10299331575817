import { currencyFormat1 } from '@/utils/currency'

//输入框千分位展示
const money = {
    mounted(el, binding) {
        const element = el
        let refresh = true
        // element.addEventListener('focus', function () {
        //     if (element.value) {
        //         element.value = parseFloat(element.value.replace(/,/g, '')).toString();
        //     }
        //     element.dispatchEvent(new Event("input"));
        // });
        // element.addEventListener('blur', function () {
        //     if (element.value && parseFloat(element.value).toString() !== "NaN") {
        //         element.value = currencyFormat1(element.value)
        //         //element.value = parseFloat(element.value).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
        //     }
        //     else {
        //         element.value = ''
        //     }
        //     element.dispatchEvent(new Event("input"));//调用input事件使vue v-model绑定更新
        // });
        element.addEventListener('input', function () {
            if (element.value && parseFloat(element.value).toString() !== "NaN") {
                if(element.value !== currencyFormat1(element.value)){
                    element.value = currencyFormat1(element.value)
                    refresh = true
                    element.dispatchEvent(new Event("input"));//调用input事件使vue v-model绑定更新
                }
            }else {
                if(refresh){
                    element.value = ''
                    refresh = false
                    element.dispatchEvent(new Event("input"));//调用input事件使vue v-model绑定更新
                }
            }
        })
    },
};


export default money;