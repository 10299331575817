import { createRouter, createWebHistory } from 'vue-router'
import { message } from 'ant-design-vue'
import { routes, asyncRoutes } from './routes'
import { useUserStore } from '@/store/modules/user'

const router = createRouter({
  routes: [...routes, ...asyncRoutes],
  history: createWebHistory(import.meta.env.BASE_URL),
})

router.beforeEach(async (to, from, next) => {
  // if (to.matched.length === 0) {
  //   return next({ name: '404' })
  // }
  const toPath = to.path
  const whiteList = ['/auth/login', '/auth/reset', '/auth/forget', '/auth/lose-efficacy', '/permission', '/*']
  if (whiteList.includes(toPath)) {
    return next()
  }

  const user = useUserStore()
  if (!user.token) {
    return next({ name: 'login' })
  }

  // 权限路由拦截
  const role = to.meta.roleCode
  const permission = user.getUserPermission
  const roleArr = (permission || []).map((_) => _.routeCode)
  if (role === 'default' || roleArr.includes(role)) {
    return next()
  } else {
    await message.error('访问权限不足')
    return next({ name: '404' })
  }
})
router.afterEach(() => {
  window.scrollTo(0, 0)
})
export default router
