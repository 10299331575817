import Layout from '@/layouts/base-layout/index.vue'
import Empty from '@/layouts/base-layout/empty.vue'

import MenuOrder from '@/assets/images/icon/button/menu-order.svg'
import MenuOrderActve from '@/assets/images/icon/button/menu-order-active.svg'
import MenuStock from '@/assets/images/icon/button/menu-stock.svg'
import MenuStockrActve from '@/assets/images/icon/button/menu-stock-active.svg'
import MenuClient from '@/assets/images/icon/button/menu-client.svg'
import MenuClientActve from '@/assets/images/icon/button/menu-client-active.svg'
import MenuSetting from '@/assets/images/icon/button/menu-setting.svg'
import MenuSettingActve from '@/assets/images/icon/button/menu-setting-active.svg'

const routes = [
  {
    path: '/auth/login',
    name: 'login',
    component: () => import('@/views/auth/login/index.vue'),
    meta: {
      title: '登录',
      roleCode: 'default',
    },
  },
  {
    path: '/auth/update-password',
    name: 'reset',
    component: () => import('@/views/auth/update-password/index.vue'),
    meta: {
      title: '重置密码',
      roleCode: 'default',
    },
  },
  {
    path: '/permission',
    name: 'permission',
    component: () => import('@/views/permission/index.vue'),
    meta: {
      title: 'permission',
      roleCode: 'default',
    },
  },
  {
    path: '/*',
    name: '404',
    component: () => import('@/views/404/index.vue'),
    meta: {
      title: '404',
      roleCode: 'default',
    },
  },
]

const asyncRoutes = [
  {
    path: '/order',
    component: Layout,
    redirect: { name: 'orderCreateIndex' },
    meta: {
      title: '订单管理',
      icon: MenuOrder,
      icon_checked: MenuOrderActve,
      roleCode: 'Order',
    },
    children: [
      {
        path: 'create',
        component: Empty,
        redirect: { name: 'orderCreateIndex' },
        meta: {
          title: '新建订单',
          roleCode: 'Order Create',
        },
        children: [
          {
            path: 'index',
            component: () => import('@/views/order/create/index.vue'),
            name: 'orderCreateIndex',
            meta: {
              title: '零购订单',
              roleCode: 'CF Create',
            },
          },
          {
            path: 'batch',
            component: () => import('@/views/order/create/batch.vue'),
            name: 'orderCreateBatch',
            meta: {
              title: '批量订单',
              roleCode: 'PL Create',
            },
          },
        ],
      },
      {
        path: 'mycreate',
        component: Empty,
        redirect: { name: 'orderMyCreateIndex' },
        meta: {
          title: '我创建的',
          roleCode: 'Order MySelf',
        },
        children: [
          {
            path: 'index',
            component: () => import('@/views/order/myCreate/index.vue'),
            name: 'orderMyCreateIndex',
            meta: {
              title: '常规订单',
              roleCode: 'Order MySelf',
            },
          },
          {
            path: 'lens',
            component: () => import('@/views/order/myCreate/lens.vue'),
            name: 'orderMyCreateLens',
            meta: {
              title: '镜片视图',
              roleCode: 'Order MySelf',
            },
          },
          {
            path: 'afterSales',
            component: () => import('@/views/order/myCreate/afterSales.vue'),
            name: 'orderMyCreateAfterSales',
            meta: {
              title: '售后订单',
              roleCode: 'Order MySelf',
            },
          },
          {
            path: 'history',
            hidden: true,
            component: () => import('@/views/order/common/history.vue'),
            name: 'orderMyCreateHistory',
            meta: {
              title: '我的操作历史',
              // roleCode: 'Order MySelf',
            },
          },
          {
            path: 'STorder',
            component: () => import('@/views/order/common/STorder.vue'),
            hidden: true,
            name: 'orderMyCreateSTorder',
            meta: {
              title: '我的ST订单',
              // roleCode: 'Order MySelf',
            },
          },
          {
            path: 'RXorder',
            component: () => import('@/views/order/common/RXorder.vue'),
            hidden: true,
            name: 'orderMyCreateRXorder',
            meta: {
              title: '我的RX订单',
              // roleCode: 'Order MySelf',
            },
          },
          {
            path: 'garageorder',
            component: () => import('@/views/order/create/index.vue'),
            hidden: true,
            name: 'orderMyCreateGarage',
            meta: {
              title: '零购订单',
              // roleCode: 'Order MySelf',
            },
          },
          {
            path: 'batchorder',
            component: () => import('@/views/order/create/batch.vue'),
            hidden: true,
            name: 'orderMyCreateBatch',
            meta: {
              title: '批量订单',
              // roleCode: 'Order MySelf',
            },
          },
          {
            path: 'InitiateAfterSales',
            component: () => import('@/views/order/common/InitiateAfterSales.vue'),
            hidden: true,
            name: 'orderInitiateAfterSales',
            meta: {
              title: '发起售后',
              // roleCode: 'Order MySelf',
            },
          },

          {
            path: 'AfterSalesGuarantee',
            component: () => import('@/views/order/common/AfterSalesGuarantee.vue'),
            hidden: true,
            name: 'afterSalesGuarantee',
            meta: {
              title: '换新计划',
              // roleCode: 'Order MySelf',
            },
          },

          {
            path: 'AfterSalesDetails',
            component: () => import('@/views/order/common/AfterSalesDetails.vue'),
            hidden: true,
            name: 'AfterSalesDetails',
            meta: {
              title: '售后详情',
              // roleCode: 'Order MySelf',
            },
          },
          {
            path: 'test',
            component: () => import('@/views/order/common/test.vue'),
            hidden: true,
            name: 'orderMyCreateTest',
            meta: {
              title: '这是测试',
              // roleCode: 'Order MySelf',
            },
          },
        ],
      },
      {
        path: 'myapproval',
        component: Empty,
        redirect: { name: 'orderMyApprovalIndex' },
        meta: {
          title: '我审批的',
          roleCode: 'Order Audit',
        },
        children: [
          {
            path: 'index',
            name: 'orderMyApprovalIndex',
            component: () => import('@/views/order/myApproval/index.vue'),
            meta: {
              title: '常规订单',
              roleCode: 'Order Audit',
            },
          },
          {
            path: 'lens',
            name: 'orderMyApprovalLens',
            component: () => import('@/views/order/myApproval/lens.vue'),
            meta: {
              title: '镜片视图',
              roleCode: 'Order Audit',
            },
          },
          {
            path: 'afterSales',
            name: 'orderMyApprovalAfterSales',
            component: () => import('@/views/order/myApproval/afterSales.vue'),
            meta: {
              title: '售后订单',
              roleCode: 'Order Audit',
            },
          },

          {
            path: 'myApprovalAfterSalesDetails',
            component: () => import('@/views/order/common/AfterSalesDetails.vue'),
            hidden: true,
            name: 'myApprovalAfterSalesDetails',
            meta: {
              title: '售后详情',
              // roleCode: 'Order Audit',
            },
          },

          {
            path: 'history',
            hidden: true,
            component: () => import('@/views/order/common/history.vue'),
            name: 'orderMyApprovalHistory',
            meta: {
              title: '我的审批历史',
              // roleCode: 'Order Audit',
            },
          },
          {
            path: 'STorder',
            component: () => import('@/views/order/common/STorder.vue'),
            hidden: true,
            name: 'orderMyApprovalSTorder',
            meta: {
              title: '我的ST订单',
              // roleCode: 'Order Audit',
            },
          },
          {
            path: 'RXorder',
            component: () => import('@/views/order/common/RXorder.vue'),
            hidden: true,
            name: 'orderMyApprovalRXorder',
            meta: {
              title: '我的RX订单',
              // roleCode: 'Order Audit',
            },
          },
          {
            path: 'garageorder',
            component: () => import('@/views/order/create/index.vue'),
            hidden: true,
            name: 'orderMyApprovalCreateGarage',
            meta: {
              title: '零购订单详情',
              // roleCode: 'Order Audit',
            },
          },
          {
            path: 'batchorder',
            component: () => import('@/views/order/create/batch.vue'),
            hidden: true,
            name: 'orderMyApprovalBatch',
            meta: {
              title: '批量订单详情',
              // roleCode: 'Order Audit',
            },
          },

          {
            path: 'orderMyApproval',
            component: () => import('@/views/order/myApproval/approval.vue'),
            hidden: true,
            name: 'orderMyApproval',
            meta: {
              title: '审批拆分',
              // roleCode: 'Order Audit',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/stock',
    component: Layout,
    redirect: { name: 'stock' },
    meta: {
      title: '库存管理',
      icon: MenuStock,
      icon_checked: MenuStockrActve,
      roleCode: 'Stock',
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/stock/index.vue'),
        name: 'stock',
        meta: {
          title: '库存管理',
          roleCode: 'Stock',
        },
      },
      {
        path: 'enAndExitdetail',
        name: 'enAndExitdetail',
        hidden: true,
        component: () => import('@/views/stock/enAndExitdetail.vue'),
        meta: {
          title: '入库详情',
          roleCode: 'Stock',
        },
      },
    ],
  },
  {
    path: '/maindata',
    component: Layout,
    redirect: { name: 'maindataInfo' },
    meta: {
      title: '主数据管理',
      icon: MenuClient,
      icon_checked: MenuClientActve,
      roleCode: 'Metadata',
    },
    children: [
      {
        path: 'index',
        name: 'maindataInfo',
        component: () => import('@/views/maindata/index.vue'),
        meta: {
          title: '主数据管理',
          roleCode: 'Metadata',
        },
      },
    ],
  },
  {
    path: '/account',
    component: Layout,
    redirect: { name: 'account-user' },
    meta: {
      title: '系统设置',
      icon: MenuSetting,
      icon_checked: MenuSettingActve,
      roleCode: 'System Setting',
    },
    children: [
      {
        path: 'index',
        name: 'account-user',
        component: () => import('@/views/account/user/index.vue'),
        meta: {
          title: '系统设置',
          roleCode: 'System Setting',
        },
      },
    ],
  },
]

export { routes, asyncRoutes }
