<template>
  <router-view v-slot="{ Component }">
    <keep-alive :include="keepAliveInclude">
      <component :is="Component" :key="route.path" />
    </keep-alive>
  </router-view>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const keepAliveInclude = ref([])

watch(
  () => route.path,
  (newVal, oldVal) => {
    console.info('history', history)
    if (history.state.needRefresh) {
      keepAliveInclude.value = []
    } else if (newVal.includes('/order/mycreate')) {
      keepAliveInclude.value = ['MyCreate']
    }
    // eslint-disable-next-line no-empty
    else if (newVal.includes('/order/myapproval/history') && oldVal?.includes('order/mycreate/batchorde')) {
    } else if (newVal.includes('/order/myapproval')) {
      keepAliveInclude.value = ['MyApproval']
    } else if (
      (!newVal.includes('/order/myapproval') && oldVal?.includes('/order/myapproval')) ||
      (!newVal.includes('/order/mycreate') && oldVal?.includes('/order/mycreate'))
    ) {
      keepAliveInclude.value = []
    }
  },
  {
    immediate: true,
  }
)
</script>
