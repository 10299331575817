import { defineStore } from 'pinia'
import { routes } from '@/router/routes'
import { filterRoutesForAuthority } from '@/utils/utils'
import { useAccountStore } from '@/store/modules/account'
import { useOrderStore } from '@/store/modules/order'
import { containsElement } from '@/utils/utils'

export const useUserStore = defineStore('user', {
  state() {
    return {
      info: {},
      token: '',
      routerList: [],
      ifMark: false, //默认false
    }
  },
  getters: {
    getUserInfo(state) {
      try {
        return state.info.userBase
      } catch (e) {
        return {}
      }
    },
    getUserName(state) {
      try {
        return state.info.userBase.userName
      } catch (e) {
        return ''
      }
    },
    getRoles(state) {
      try {
        return state.info.roles.map((item) => item.name)
      } catch (e) {
        return []
      }
    },
    getUserId(state) {
      try {
        return state.info.userId || state.info.userBase.userId
      } catch (e) {
        return ''
      }
    },
    getUserRole(state) {
      try {
        return state.info.roles[0].roleName
      } catch (e) {
        return ''
      }
    },
    getUserPermission(state) {
      return state.info.routes
    },
    checkInPermission(state) {
      return (value) => {
        let authority = false
        const routes = state.info.routes
        if (routes && Array.isArray(routes)) {
          const index = routes.findIndex((item) => item.routeCode === value)
          authority = index > -1
        }
        return authority
      }
    },

    getIfMark(state) {
      return state.ifMark
    },

    // 首页
    getUserPageIndex(state) {
      const roles = state.getRoles
      const permission = state.getUserPermission
      let roleArr = (permission || []).map((_) => _.routeCode)
      roleArr = [...new Set(roleArr)]
      if (!roleArr.length) {
        return '/permission'
      }

      // let defaultPath = {
      //   'System Setting': '/account',
      //   'Metadata': '/client',
      //   'Stock': '/stock',
      //   'Order': '/order',
      // }
      // const defaultPathKey = Object.keys(defaultPath)

      if (roles.length > 0) {
        if (containsElement(['super', 'admin', 'orderExport'], roles)) {
          return '/order/myapproval/index'
        } else {
          return '/order/mycreate/index'
        }
      }

      // for (let i = 0, len = roleArr.length; i < len; i++) {
      //   if (defaultPathKey.includes(roleArr[i])) {
      //     return defaultPath[roleArr[i]]
      //   }
      // }
      return '/permission'
    },
  },
  actions: {
    setUser(info) {
      this.info = info
    },
    setToken(token) {
      this.token = token
    },
    setUserNamePhone(name, phone) {
      this.info.userBase.userName = name
      this.info.userBase.userPhone = phone
    },
    logout() {
      useAccountStore().$reset()
      useOrderStore().$reset()
      this.$reset()
    },
    setRouterList(data) {
      this.routerList = filterRoutesForAuthority(routes, data.auth)
    },

    setIfMark(ifMark) {
      this.ifMark = ifMark
    },
  },
  persist: {
    enabled: true,
  },
})
