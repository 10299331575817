<template>
  <a-layout>
    <a-layout-sider width="252">
      <div class="header-logo">
        <img class="header-logo__icon" :src="IconLogo" alt="logo" @click="toIndex" />
        <div>订单管理系统</div>
      </div>
      <sidebar></sidebar>
    </a-layout-sider>
    <!-- 左侧菜单 -->
    <a-layout class="layout-customer-content">
      <UHeader />
      <!-- 页面内容承载区 -->
      <a-layout-content class="flex-column layout-customer-content-content">
        <a-layout-content class="wihite-bg">
          <!-- <a-spin :spinning="app.contentLoading"> -->
          <router-view />
          <!-- </a-spin> -->
        </a-layout-content>
        <!-- 加入页面动画 -->
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useUserStore } from '@/store/modules/user'
import UHeader from './components/UHeader.vue'
import sidebar from './components/Sidebar.vue'
import IconLogo from '@/assets/images/icon/logo.svg'

const router = useRouter()
const user = useUserStore()

const toIndex = async () => {
  const rootPath = await user.getUserPageIndex
  router.push(rootPath)
}
</script>

<style lang="less">
.ant-layout {
  background: #f3f3f3;
}
</style>

<style scoped lang="less">
.header-logo {
  width: var(--sidebar-width);
  height: var(--header-height);
  background: #242424;
  color: #fff;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #383838;
  padding-left: 16px;
  .header-logo__icon {
    width: 56px;
    height: 48px;
    cursor: pointer;
    user-select: none;
    margin-right: 16px;
  }
}

.layout-customer-content {
  overflow-y: auto;
  height: 100%;
  width: calc(100vw - var(--sidebar-width));
  margin-left: var(--sidebar-width);

  .layout-customer-content-content {
    margin-top: var(--header-height);
    position: relative;
    .wihite-bg {
      background: #ffffff;
      margin: 32px;
      padding: 32px;
      min-height: calc(100vh - var(--header-height) - 64px);
      box-shadow: 0 1px 10px rgba(0, 0, 0, 0.02), 0 4px 5px rgba(0, 0, 0, 0.05), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      user-select: none;
    }
  }
}
.bottom-copy {
  position: absolute;
  left: 32px;
  bottom: 4px;
}
</style>
