import { createApp } from 'vue'
import store from './store'
import router from '@/router/index'
import Antd from 'ant-design-vue'
import App from './App.vue'

import 'ant-design-vue/dist/antd.less'
import '@/styles/index.less'

import DirectiveAuthority from '@/directive/authority'
import DirectiveMoney from '@/directive/money'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(Antd)

app.directive('authority', DirectiveAuthority)
app.directive('money', DirectiveMoney)

app.mount('#app')
