import dayjs from 'dayjs'

function imgDataToBlob(img) {
  return new Promise(function (resolve) {
    const canvas = document.createElement('canvas')
    canvas.width = img.width
    canvas.height = img.height
    const ctx = canvas.getContext('2d')
    ctx?.drawImage(img, 0, 0, canvas.width, canvas.height)
    canvas.toBlob((blob) => {
      resolve(blob)
    })
  })
}

/**
 * url地址转Blob
 * @param imgSrc
 * @returns
 */
export function imgSrcToBlob(imgSrc) {
  return new Promise(function (resolve) {
    const image = new Image()
    image.setAttribute('crossOrigin', 'Anonymous')
    image.src = `${imgSrc}?v=${new Date()}`
    image.onload = async function () {
      resolve(await imgDataToBlob(image))
    }
  })
}

/**
 * 下载Blob
 * @param blob
 * @param filename
 */
export function downloadBlob(blob, filename) {
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  const event = new MouseEvent('click')
  a.download = filename
  a.href = url
  a.dispatchEvent(event)
  URL.revokeObjectURL(url)
}

/**
 * 下载图片
 * @param src
 * @param filename
 */
export function downloadImg(src, filename) {
  imgSrcToBlob(src).then((res) => {
    downloadBlob(res, filename)
  })
}

/**
 * 树转换成一维列表
 * @param tree
 * @param parentId
 * @param param2
 * @returns
 */
export const treeToList = (tree) => {
  let ret = []
  tree.forEach((o) => {
    ret.push(o)
    if (o.children?.length) {
      ret = ret.concat(treeToList(o.children))
    }
  })
  return ret
}

/**
 * 解析路由 /system/user => ['/system', '/system/user']
 * @param route
 * @returns
 */
export const parseRoute = (route) => {
  const routeList = route.split('/')
  routeList.splice(0, 1)
  const initArr = []
  return routeList.reduce((a, b) => {
    return a.concat([`${a}/${b}`])
  }, initArr)
}

/**
 * 根据path获取完整的route对象列表
 * @param routeTree
 * @param routeArr
 * @returns
 */
export const getRouteList = (routeTree, routeArr) => {
  const routes = treeToList(routeTree)
  return routeArr.map((path) => {
    return routes.find((item) => item.path === path)
  })
}

/**
 * 过滤需隐藏菜单
 * @param routes
 */
export const filterRoutes = (routes) => {
  const arr = []
  routes.forEach((item) => {
    if (!(item.meta && item.meta.hide)) {
      if (item.children?.length) {
        arr.push({ ...item, children: filterRoutes(item.children) })
      } else {
        arr.push({ ...item })
      }
    }
  })
  return arr
}

/**
 * 当子路由只有一个时提升
 */
export const promoteRoute = (routes) => {
  return routes.map((item) => {
    if (item.children && item.children.length === 1) {
      return { ...item, children: [] }
    }
    return { ...item }
  })
}

/**
 * 防抖函数
 * @param func
 * @param wait
 * @param immediate
 */
export const debounce = (func, wait, immediate) => {
  let timeout, args, context, timestamp, result

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function (...args) {
    // @ts-ignore
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

export const local = {
  get(key) {
    if (key) {
      const item = window.localStorage.getItem(key)
      if (item) {
        return JSON.parse(item)
      }
    }
  },
  set(key, value) {
    if (key && value) {
      const item = JSON.stringify(value)
      window.localStorage.setItem(key, item)
    }
  },
  remove(key) {
    if (key) {
      window.localStorage.removeItem(key)
    }
  },
  clear() {
    window.localStorage.clear()
  },
}

export const authToFlat = (auth) => {
  const result = []

  const recursion = (data) => {
    data.forEach((item) => {
      result.push(item)
      if (Array.isArray(item.children) && item.children.length > 0) {
        recursion(item.children)
      }
    })
  }
  recursion(auth)

  return result
}

export const filterRoutesForAuthority = (routes, auth) => {
  const mainRoutes = routes.find((route) => route.name === 'index')
  mainRoutes.children = auth

  return routes
}

export const durationTime = (start, end) => {
  try {
    let str = ''
    const startTime = Math.ceil(new Date(start).getTime() / 1000)
    const endTime = Math.ceil(new Date(end).getTime() / 1000)
    const length = Math.abs(endTime - startTime)
    const year = Math.floor(length / 31536000)
    const yearRemainder = length % 31536000
    year > 0 && (str += `${year}年`)
    const day = Math.floor(yearRemainder / 86400)
    const dayRemainder = yearRemainder % 86400
    day > 0 && (str += `${day}天`)
    const hour = Math.floor(dayRemainder / 3600)
    const hourRemainder = dayRemainder % 3600
    hour > 0 && (str += `${hour}小时`)
    const min = Math.floor(hourRemainder / 60)
    const minRemainder = hourRemainder % 60
    min > 0 && (str += `${min}分`)
    minRemainder > 0 && (str += `${minRemainder}秒`)
    return str
  } catch (err) {
    return ''
  }
}

/**
 * 获取动态第一个路由
 * @param routerList
 */
export const getFirstPath = (routerList) => {
  if (Array.isArray(routerList) && routerList.length) {
    const child = routerList[0].children
    if (Array.isArray(child) && child.length) {
      return getFirstPath(child)
    } else {
      return routerList[0].path
    }
  } else {
    return '/apply/record'
  }
}

/**
 * @description 将连续数字组合
 * @example [1, 2, 3, 6, 7, 9, 11] => [[1, 2, 3], [6, 7], [9], [11]]
 * @param arr
 * @return {*[]}
 */
export const continuityNumber = (arr) => {
  if (!Array.isArray(arr) || !arr.length) {
    return []
  }

  const result = []
  let i = 0
  result[i] = [arr[0]]
  arr.reduce((prev, curr) => {
    curr - prev === 1 ? result[i].push(curr) : (result[++i] = [curr])
    return curr
  })
  return result
}

/**
 * @description 将连续数字组合并格式
 * @example [1, 2, 3, 6, 7, 9, 11] => ['1-3', '6-7', '9', '11']
 * @param arr
 * @return {unknown[]|*[]}
 */
export const continuityNumberFormat = (arr) => {
  if (!Array.isArray(arr) || !arr.length) {
    return []
  }

  const newArr = continuityNumber(arr)
  return newArr.map((item) => {
    if (item.length > 1) {
      return `${item[0]}-${item[item.length - 1]}`
    } else {
      return `${item[0]}`
    }
  })
}

/**
 * 密码强度检测
 * @param value
 * @return {boolean}
 */
export const passwordStrengthMeter = (value) => {
  const reg = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^a-zA-Za-z\d\s])^.{8,}$/
  return reg.test(value)
}

/**
 * 日期比较，传入时间在当前时间之前
 * @param date
 * @return {boolean}
 */
export const activityStatus = (date) => {
  if (!date) {
    return false
  }
  const currentDate = new Date()
  const diff = dayjs(date).diff(dayjs(currentDate), 'seconds')
  return diff < 0
}

/**
 * 数组删除元素
 * @param resource
 * @param deleteItem
 * @return {*}
 */
export const arrDeleteItem = (resource, deleteItem) => {
  if (!Array.isArray(deleteItem)) {
    deleteItem = [deleteItem]
  }

  return resource.filter((e) => !deleteItem.includes(e))
}

//小数取一位
export function formatterRoundOneData(num) {
  let processedNum
  let origin = num
  if (typeof origin === 'string') {
    origin = parseFloat(origin)
  }

  if (Number.isInteger(origin)) {
    processedNum = Math.round(origin) // 如果是整数，取整
  } else {
    processedNum = parseFloat(origin.toFixed(1)) // 如果是小数，保留一位小数
  }
  return processedNum
}

export function containsElement(arr1, arr2) {
  // 遍历 arr2 中的每个元素
  for (let i = 0; i < arr2.length; i++) {
    // 使用 includes 方法检查 arr1 是否包含当前元素
    if (arr1.includes(arr2[i])) {
      return true // 如果包含其中一个元素，返回 true
    }
  }
  return false // 如果没有匹配的元素，返回 false
}

export function changeTime(str) {
  if (str && str.length > 10) {
    return str.substring(0, 11)
  } else {
    return '-'
  }
}
