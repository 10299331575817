import { defineStore } from 'pinia'

export const useOrderStore = defineStore('order', {
  state() {
    return {
      rejectList: [],
      illegalOrderItemProductIds: [],
    }
  },
  actions: {
    setRejectList(data) {
      this.rejectList = data
    },
    setIllegalOrderItemProductIds(data) {
      this.illegalOrderItemProductIds = data || []
    }
  },
  persist: {
    enabled: true
  }
})
