import currency from 'currency.js'

/**
 * 金额格式转换 1234567.09 => 1,234,567.09
 * @param num
 * @return {string}
 */
export const currencyFormat = (num) => {
  const decimalPart = String(num).split('.')[1];
  const precision = decimalPart ? decimalPart.length : 0;
  return currency(num, { symbol: '', precision }).format()
}
/**
 * 金额格式转换 1234567.09 => 1,234,567
 * @param num
 * @return {string}
 */
export const currencyFormat0 = (num, noParseZero) => {
  if(noParseZero && !num){
    return num
  } else{
    return currency(num, { symbol: '', precision: 0 }).format()
  }
}

/**
 * 金额格式转换 大于一百万 1234567890.09 => 123,4567,890
 * @param num
 * @return {string}
 */
export const currencyFormat1 = (num=0) => {
  const value = currency(num);
  if (value.value >= 1000000) {
    return currencyFormat0(value.value);
  } else {
    return currencyFormat(num)
  }
}

//千分位转数字
export const thousandsToNumber = (value)=> {
	if (typeof value === "number") {
		return value
	} else {
		return value === "" || value === undefined || value === null ? null : parseFloat(value.replace(/,/g, ''))
	}

}
