<template>
  <a-affix class="layout-header" :offset-top="0">
    <div class="header-container">
      <div class="header-right">
        <a-dropdown class="header-right__item" placement="bottomRight" :trigger="['click', 'hover']">
          <div>
            <img class="header-right__icon" :src="IconUser" alt="user" />
            <span class="header-right__text">{{ userName }}</span>
            <img class="header-right__icon" :src="IconDown" alt="down" />
          </div>
          <template #overlay>
            <a-menu class="header-dropdown-menu">
              <a-menu-item
                v-for="(item, i) in userOptions"
                :key="i"
                class="header-dropdown-menu__item"
                @click="item.onClick"
                >{{ item.content }}</a-menu-item
              >
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </div>
  </a-affix>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { computed, readonly } from 'vue'
import { useUserStore } from '@/store/modules/user'
import IconUser from '@/assets/images/icon/user-white.svg'
import IconDown from '@/assets/images/icon/slidedown.svg'

import { logout as apiLogout } from '@/api/auth'

const user = useUserStore()
const router = useRouter()

const userName = computed(() => user.getUserName)
const userRole = computed(() => user.getUserRole)

const updatePassword = () => {
  router.push('/auth/update-password')
}
const logout = async () => {
  await apiLogout().then()
  await router.replace('/auth/login').then()
  user.logout()
}

const userOptions = readonly([
  { content: '修改密码', value: 1, onClick: updatePassword },
  { content: '退出登录', value: 2, onClick: logout },
])
</script>

<style scoped lang="less">
.layout-header {
  width: calc(100vw - var(--sidebar-width));
  color: var(--header-color);
  background-color: var(--header-bgc);
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.02), 0 4px 5px rgba(0, 0, 0, 0.05), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  user-select: none;
  position: fixed;
  z-index: 200;

  :deep(.ant-affix) {
    background-color: var(--header-bgc);
    position: relative;
    z-index: 9999;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.02), 0 4px 5px rgba(0, 0, 0, 0.05), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    user-select: none;
  }
}

.header-container {
  width: 100%;
  height: var(--header-height);
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-main {
  flex: 1;
}

.header-right {
  min-width: 190px;
  flex-shrink: 0;
  margin-left: 20px;
  margin-right: 32px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;

  .header-right__item {
    display: flex;
    align-items: center;
    cursor: pointer;

    & + .header-right__item {
      margin-right: 25px;
    }
    .header-right__icon {
      width: 21px;
      height: 21px;
      &.order-icon {
        width: 18px;
        height: 18px;
        margin-top: 1px;
      }
      &.upload-icon {
        width: 18px;
        height: 22px;
      }
    }
    .header-right__text {
      flex-shrink: 0;
      font-size: 16px;
      line-height: 24px;
      margin-left: 10px;
    }
  }
}

.header-dropdown-menu {
  min-width: 160px;
  padding: 8px;
  box-sizing: border-box;
}
:deep(.header-dropdown-menu__item) {
  height: 28px;
  box-sizing: border-box;
}
</style>
