import request from "@/utils/request"

/**
 * @description 忘记密码，修改密码
 * @methods post
 * @param {Object} data
 * @param {String} data.originalUserPwd 原密码
 * @param {String} data.userPwd 新密码
 * @return {Promise<>}
 */
export const changePwd = (data) => {
  return request.post('/lenses/api/login/changePwd', data)
}

/**
 * @description 获取当前登录用户信息
 * @methods get
 * @return {Promise<>}
 */
export const loginInfo = () => {
  return request.get('/lenses/api/login/info')
}

/**
 * @description 登录，返回Token
 * @methods post
 * @param {Object} data
 * @param {String} data.userName 账号或邮箱
 * @param {String} data.userPwd  登录密码
 * @return {Promise<>}
 */
export const login = (data) => {
  return request.post('/lenses/api/login/login', data)
}

/**
 * @description 退出登录
 * @methods get
 * @return {Promise<>}
 */
export const logout = () => {
  return request.get('/lenses/api/login/logout')
}


export default {
  changePwd,
  loginInfo,
  login,
  logout,
}
